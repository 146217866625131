import { createGlobalStyle } from 'styled-components'
import { resetStyles } from '../styles/reset'

const GlobalStyle = createGlobalStyle`
  ${resetStyles()}
  html {
    font-size: 10px;
  }
  @media (max-width: 1000px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    html {
      font-size: 7px;
    }
  }
  body {
    font-family: ${props => props.theme.fonts.helvetica};
    font-weight: 300;
    line-height: 1.2;
    background-color: #121212;
  }

  h1, h2, h3, li {
    font-family: ${props => props.theme.fonts.baskerville};
    color: #fff;
    font-weight: 300;
  }
  a {
    color: ${props => props.theme.colours.primary};
  }
  p {
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 1.5rem;
    line-height: 2.1;
    color: #fff;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1000px) {
    p {
      font-size: 2rem;
    }
  }
  .container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 119rem;
    padding: 0 3rem;
  }
  @media (max-width: 650px) {
    .container {
      padding: 0 4rem;
    }
  }
  .main {
    overflow: hidden;
  }
`

export default GlobalStyle
