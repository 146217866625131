import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { AboutOuter, Intro, Team, TeamImage } from './styles'
import { Sidebar } from '../hero/styles'

const HomeHero = () => {
  const images = useStaticQuery(graphql`
    {
      allFile(filter: { dir: { regex: "/src/" }, extension: { eq: "jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const davidImage = images.allFile.edges.find(image =>
    image.node.childImageSharp.fluid.src.includes('david-bennet')
  )

  const danielaImage = images.allFile.edges.find(image =>
    image.node.childImageSharp.fluid.src.includes('daniela-mascetti')
  )

  return (
    <AboutOuter>
      <Sidebar />
      <Intro>
        <h2>About</h2>
        <p>
          The Understanding Jewellery website will be launched soon. By subscribing you will gain
          access to a vast, ever-expanding resource of jewellery knowledge, supported by over 1,000
          images dating from 1750 to the present day. The site will also offer live and online
          educational seminars, available for purchase, and exclusive international tours hosted by
          David and Daniela. A special feature of the website will be the presentation of their
          personal election and appreciation of the most exciting jewels which they have discovered
          in the current worldwide marketplace.
        </p>
        <p>
          From its first publication over 30 years ago and translations into many languages,
          <em> Understanding Jewellery</em>, the book, is now established as the authority in the
          jewellery world. The unrivalled expertise and sincere passion for what lies beyond a rare
          jewel has now seen David and Daniela’s knowledge translated into online digital formats,
          including an App. Their brand new work, <em>Understanding Jewellery: The 20th Century</em>
          , is due for publication later this year.
        </p>
      </Intro>

      {/* <Team>
        <div>
          <TeamImage fluid={davidImage.node.childImageSharp.fluid} />
          <h2>David Bennett</h2>
          <p>
            The Worldwide Chairman of Sotheby’s Jewellery division, David Bennett is regarded
            internationally as an authority in the field of precious stones and jewellery. An
            auctioneer for the firm since 1978, David has valued and handled the sale of some of the
            world’s most spectacular jewels and collections, including three out of the five most
            expensive jewels to sell in auction history. These include the world auction record for
            any diamond or gemstone: the “CTF Pink Star”, a 59.60-carat Fancy Vivid Pink Internally
            Flawless diamond which sold for $71.2 million in April 2017. In May 2016, David
            established the world record for any jewellery sale, achieving a total of $175.1 million
            for Sotheby’s sale of Magnificent Jewels and Noble Jewels in Geneva, while in November
            2018 he held the hammer for the fabled collection of Royal Jewels from the Bourbon Parma
            family, featuring astounding jewels that once belonged to Marie Antoinette. The sale
            totalled $55 million and set a new benchmark for an auction of antique jewellery. In his
            private life David has been a practicing astrologer for many years and designs unique
            talismanic jewels.
          </p>
        </div>
        <div>
          <TeamImage fluid={danielaImage.node.childImageSharp.fluid} />
          <h2>Daniela Mascetti</h2>
          <p>
            As Chairman of Sotheby's Jewellery Division, Europe, Daniela Mascetti is one of the
            world's most experienced scholars specialising in the history of jewellery. After
            graduating in Classics in Italy she joined Sotheby's in 1980 opening the firm’s
            Jewellery division in Milan, Italy. Daniela has been closely involved in Sotheby’s most
            important jewellery sales beginning in 1987 with the fabulous jewels of the Duchess of
            Windsor, wife of King Edward VIII. She has contributed to the vital scholastic research
            for the sales of historic collections, notably the Royal Jewels from the Bourbon Parma
            family including treasures from Queen Marie Antoinette, and the princely collections of
            the von Thurn und Taxis family. Daniela has published extensively co-authoring, amongst
            others, volumes on the evolution of earrings and necklace design and the first monograph
            on the Italian jewellery maison Bulgari. Daniela travels worldwide to lecture in the
            field of the history and development of jewellery design and its close relationship with
            the evolution in political, social and dress fashion history.
          </p>
        </div>
      </Team> */}
    </AboutOuter>
  )
}

export default HomeHero
