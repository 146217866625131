import styled from 'styled-components'

export const MoreContainer = styled.section`
  display: flex;
  background: #fff;
  z-index: 5;
`

export const MoreOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  margin: 12rem auto;
  background: #fff;
`

export const MoreInner = styled.div`
  text-align: center;

  h2 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-weight: 300;
    font-size: 3.5rem;
    color: #000;
  }

  p {
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 1.5rem;
    line-height: 2.1;
    font-weight: 200;
    color: #000;
    max-width: 72ch;
    margin: 4rem;
    -webkit-font-smoothing: auto;

    @media (max-width: 1000px) {
      font-size: 2rem;
    }
  }
`
