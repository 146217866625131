import React from 'react'

import Button from '../button'

import { MoreContainer, MoreOuter, MoreInner } from './styles'

const More = () => (
  <MoreContainer>
    <MoreOuter>
      <MoreInner>
        <h2>Want to know more?</h2>
        <p>
          The new website will be the premium platform in the industry through which to learn about
          the eternal story and enduring magic of jewellery and share in David and Daniela’s
          extensive knowledge. It will also provide up-to-date and constantly expanding information
          on global auctions, jewellery news and events, extraordinary jewels and remarkable gems.
          Lectures, jewellery courses and seminars hosted by David and Daniela will also be offered.
          The aim of the Understanding Jewellery website is to communicate and impart David and
          Daniela’s passion for jewels and gemstones and the stories behind them.
        </p>
      </MoreInner>
      <Button text="Enquire" link="mailto:info@understanding-jewellery.com" />
    </MoreOuter>
  </MoreContainer>
)

export default More
