import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

export const BookAppOuter = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  padding: 9rem 0;
  z-index: 15;

  @media (max-width: 600px) {
  }

  h2 {
    color: #000;
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }

  p {
    color: #000;
    margin-bottom: 5rem;
    -webkit-font-smoothing: auto;
    font-weight: 200;
    font-size: 1.5rem;
  }
`

export const Book = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: calc(86rem + 60px);
  padding: 0 30px;
  text-align: right;
  margin-bottom: 26rem;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    text-align: left;
    margin-bottom: 10rem;
  }

  > * {
    &:first-child {
      width: 52%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &:last-child {
      width: 43%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
`

export const BookText = styled.div``

export const BookImage = styled(Img)`
  width: 100%;
  transform: translateY(-17rem);

  @media (max-width: 1000px) {
    transform: translateY(0);
  }

  @media (max-width: 600px) {
    max-width: 250px;
    transform: translateX(-28px);
    margin-bottom: 5rem;
  }
`

export const App = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: calc(86rem + 60px);
  padding: 0 30px;

  > * {
    &:first-child {
      position: relative;
      width: 48%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &:last-child {
      width: 52%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
`

export const AppText = styled.div`
  a {
    margin: 0 20px 20px 0;
  }
`

export const AppImage = styled(Img)`
  width: 140%;
  transform: translate(-9rem, -21rem);

  @media (max-width: 600px) {
    width: 100%;
    transform: translate(0, 0);
    margin-bottom: 5rem;
  }
`
