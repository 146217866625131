const theme = {
  colours: {
    primary: '#489BD4',
    secondary: 'rebeccapurple',
    black: '#0D0D0D'
  },
  fonts: {
    poppins: `'Poppins', sans-serif`,
    baskerville: `'Libre Baskerville', serif`
  }
}

export default theme
