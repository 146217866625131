import styled, { keyframes } from 'styled-components'

const slideInFromTop = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const CookieOuter = styled.section`
  position: fixed;
  max-width: 350px;
  bottom: 3rem;
  right: 3rem;
  background: #191919;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transform: translateY(0);
  animation: ${slideInFromTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 2000;

  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  &.hidden {
    transform: translateY(150%);
  }

  p {
    line-height: 1.5;
  }
`

export const Title = styled.p`
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 2.5rem;
`

export const Buttons = styled.div`
  margin-top: 2rem;
`

export const Button = styled.button`
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 1.5rem;
  font-weight: 500;
  border: 0;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin-right: 1.5rem;
  transition: background 0.3s ease;
  background: #fff;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`
