import React from 'react'
import styled from 'styled-components'

const Checkbox = ({ className, checked, text, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
    <StyledLabel>{text}</StyledLabel>
  </CheckboxContainer>
)

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
`

const StyledLabel = styled.p`
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }
`

const Icon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.colours.primary};
  stroke-width: 0.3rem;
  transition: opacity 0.3s ease;
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 1.2rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background: ${props => (props.checked ? '#fff' : 'transparent')};
  border: 0.1rem solid rgba(255, 255, 255, 0.6);
  transition: all 150ms;
  flex-shrink: 0;
  margin-right: 0.5rem;

  @media (max-width: 1025px) {
    margin-right: 1.5rem;
  }

  ${Icon} {
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`

export default Checkbox
