import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useInput } from './input-hook'

import Checkbox from './checkbox'
import SubmitArrowSvg from '../../images/submit-arrow.svg'

import {
  FormOuter,
  SubmitWrapper,
  SubmitButtonOuter,
  SubmitButton,
  Form,
  CheckboxOuter,
  FormBlock,
  FormSuccess
} from './styles'

const Footer = () => {
  const [checkbox, setcheckbox] = useState(false)
  const [success, setSuccess] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const handleCheckboxChange = () => setcheckbox(!checkbox)

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')
  const { value: firstname, bind: bindFirstName, reset: resetFirstName } = useInput('')
  const { value: lastname, bind: bindLastName, reset: resetLastName } = useInput('')

  const handleFormSubmit = event => {
    event.preventDefault()
    resetEmail()
    resetFirstName()
    resetLastName()

    addToMailchimp(email, {
      FNAME: firstname,
      LNAME: lastname
    })
      .then(data => {
        setResponseMessage(data)
        setSuccess(true)
      })
      .catch(() => {
        console.log('Request failure')
      })
  }

  return (
    <FormOuter>
      {success && (
        <FormBlock>
          <FormSuccess className={success ? 'active' : ''}>
            <p dangerouslySetInnerHTML={{ __html: responseMessage.msg }} />
          </FormSuccess>
        </FormBlock>
      )}
      <Form onSubmit={handleFormSubmit} className={success ? 'blur' : ''}>
        <input
          type="text"
          id="mce-FNAME"
          name="FNAME"
          placeholder="name"
          required
          autoComplete="false-off"
          {...bindFirstName}
        />
        <input
          type="text"
          id="mce-LNAME"
          name="LNAME"
          placeholder="surname"
          required
          {...bindLastName}
        />
        <SubmitWrapper>
          <input
            type="email"
            id="mce-EMAIL"
            name="EMAIL"
            placeholder="enter your email"
            required
            autoComplete="false-off"
            {...bindEmail}
          />
          <SubmitButtonOuter>
            <SubmitArrowSvg />
            <SubmitButton type="submit" />
          </SubmitButtonOuter>
        </SubmitWrapper>
        <CheckboxOuter>
          <Checkbox
            checked={checkbox}
            required
            onChange={handleCheckboxChange}
            text="I agree to the storing of my personal data & for me to receive email notifications & news"
          />
        </CheckboxOuter>
      </Form>
    </FormOuter>
  )
}

export default Footer
