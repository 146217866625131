import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import GlobalStyle from './global-styles'

import Footer from './footer'

import 'typeface-poppins'
import 'typeface-libre-baskerville'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <title>Understanding Jewellery | The eternal fascination, the enduring magic</title>
      <meta
        name="description"
        content="‘Understanding Jewellery’ is an encyclopaedia of historical jewellery knowledge, and of the stories and the history behind."
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main className="main">{children}</main>
      <Footer />
    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
