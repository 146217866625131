import styled from 'styled-components'

export const Text = styled.div`
  position: relative;
  width: 100%;
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
  color: #000;
  background: #fff;
  padding: 1.5rem 2rem 1.3rem;
  border: 1px solid #000;
  transition: border 0.3s ease, color 0.3s ease;
  z-index: 2;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    padding: 2.5rem 3rem 2.2rem;
  }
`

export const Spacer = styled.div`
  position: relative;
  width: 16rem;
  height: 1px;
`

export const ButtonEl = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;
  z-index: 5;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 1px);
    border: 1px solid ${props => props.theme.colours.primary};
    transition: transform 0.3s ease;
    z-index: 1;
    transform: translate(0.5rem, 0.8rem);
  }

  &:hover {
    &::after {
      transform: translate(0, 0);
    }

    ${Text} {
      color: ${props => props.theme.colours.primary};
    }
  }
`
