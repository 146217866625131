import styled from 'styled-components'

export const VideoContainer = styled.section`
  background: #fff;
  z-index: 5;
`

export const VideoOuter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  background: #fff;
`

export const VideoInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 100rem;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  &:hover {
    .react-player__shadow {
      transform: scale(1.1);
    }
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-player__shadow {
    width: 11.6rem !important;
    height: 11.6rem !important;
    border: 1px solid #fff;
    background: none !important;
    transition: transform 0.3s ease;
    will-change: scale;
  }

  .react-player__play-icon {
    border-width: 10px 0px 10px 20px !important;
  }
`

export const Overlay = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  &.clicked {
    opacity: 0;
  }
`

export const Text = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  p {
    width: 100%;
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 2rem;
    line-height: 1.2;
    font-style: italic;
    color: #fff;
    margin: 0;
    padding: 2rem 3rem;
    border-top: 1px solid rgba(255, 255, 255, 0.4);

    @media (max-width: 860px) {
      padding-left: 30px;
    }
  }
`

export const Title = styled.p`
  width: 100%;
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 1.1rem;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: rgba(0, 0, 0, 0.5);
  transform: translateX(0.4rem);
  text-align: right;
  margin-bottom: 2rem;

  @media (max-width: 860px) {
    padding-left: 30px;
    text-align: left;
  }
`
