import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

export const AboutOuter = styled.section`
  position: relative;
  padding: 0 30px 23rem;
  background: #121212;
  z-index: 5;

  @media (max-width: 1000px) {
    padding: 0 30px 30px;
  }

  @media (max-width: 600px) {
    padding: 60px 30px 0;
  }
`

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    text-align: left;
    align-items: flex-start;
  }

  h2 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-weight: 300;
    font-size: 3.5rem;

    @media (max-width: 1000px) {
      padding-left: 7.5rem;
    }

    @media (max-width: 600px) {
      padding-left: 0;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 1.5rem;
    line-height: 2.1;
    max-width: 70ch;

    @media (max-width: 1000px) {
      padding-left: 7.5rem;
      font-size: 2rem;
    }

    @media (max-width: 600px) {
      padding-left: 0;
    }
  }
`

export const Team = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 100rem;
  margin-top: 10vw;

  @media (max-width: 1250px) {
    padding-left: 7.5rem;
  }

  @media (max-width: 750px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    padding-left: 0;
  }

  > div {
    width: calc(50% - 4.5rem);

    @media (max-width: 750px) {
      width: 100%;
      max-width: 400px;
      margin-bottom: 4rem;
    }
  }

  h2 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-weight: 300;
    font-size: 3.5rem;
    margin: 3rem 0 0;

    &::after {
      content: '';
      display: block;
      width: 8.2rem;
      height: 1px;
      margin: 2rem 0 2.5rem;
      background: rgba(255, 255, 255, 0.3);
    }
  }
`

export const TeamImage = styled(Img)`
  width: 100%;
`
