import React from 'react'

import { ButtonEl, Text, Spacer } from './styles'

const Button = ({ text, link }) => (
  <ButtonEl href={link} target="_blank">
    <Text>{text}</Text>
    <Spacer />
  </ButtonEl>
)

export default Button
