import React, { useEffect, useState } from 'react'

import { CookieOuter, Title, Buttons, Button } from './styles'

const CookieBanner = () => {
  const [show, setShow] = useState(false)
  const [animate, setAnimate] = useState(false)

  const handleButtonAccept = () => {
    localStorage.setItem('cookies', 'accepted')
    setAnimate(true)
    setTimeout(() => {
      setShow(false)
    }, 500)
  }

  const handleButtonReject = () => {
    localStorage.setItem('cookies', 'rejected')
    setAnimate(true)
    setTimeout(() => {
      setShow(false)
    }, 500)
  }

  useEffect(() => {
    if (!localStorage.getItem('cookies')) {
      setShow(true)
    }
  }, [])

  return (
    <>
      {show && (
        <CookieOuter className={animate ? 'hidden' : ''}>
          <Title>Cookies</Title>
          <p>We use cookies for a number of reasons, such as to analyse how our site is used.</p>
          <Buttons>
            <Button id="cookie-accept" type="button" onClick={handleButtonAccept}>
              Accept
            </Button>
            <Button id="cookie-reject" type="button" onClick={handleButtonReject}>
              Reject
            </Button>
          </Buttons>
        </CookieOuter>
      )}
    </>
  )
}

export default CookieBanner
