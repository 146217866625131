import styled from 'styled-components'

export const PageFooter = styled.footer`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Left = styled.div`
  display: flex;
  justify-content: center;
  width: 67%;
  background: rgba(0, 0, 0, 1);
  padding: 7rem 0;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const LeftInner = styled.div`
  width: 100%;
  max-width: 56.5rem;

  @media (max-width: 460px) {
    padding: 0 30px;
  }
`

export const Right = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  background: rgba(0, 0, 0, 0.9);
  text-align: right;

  @media (max-width: 800px) {
    width: 100%;
    padding: 7rem 0;
    text-align: center;
  }
`

export const RightInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  max-width: 28rem;

  @media (max-width: 800px) {
    align-items: center;
    max-width: 100%;
    padding: 0 30px;
  }
`

export const Heading = styled.p`
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 3rem;
  font-weight: 300;
  font-style: italic;
`

export const FooterSymbol = styled.figure`
  width: 4.4rem;

  svg {
    width: 100%;
  }
`

export const Phone = styled.p`
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 3rem;
  font-weight: 300;
  font-style: italic;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`

export const Email = styled.p`
  font-family: ${props => props.theme.fonts.baskerville};
  font-size: 1.5rem;
  font-weight: 300;
  margin: -1.5rem 0 0;
  transition: opacity 0.3s ease;

  @media (max-width: 800px) {
    font-size: 2rem;
    margin: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`

export const Social = styled.nav`
  display: flex;
  margin-top: 2rem;

  a {
    &:last-child {
      svg {
        margin-left: 8px;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    transition: opacity 0.3s ease;
    fill: #fff;

    &:hover {
      opacity: 0.6;
    }
  }
`

export const Copyright = styled.div`
  margin: 2rem 0 0;

  p {
    font-size: 1.3rem;
    margin: 0;

    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      font-size: 2rem;
      line-height: 1.6;
    }
  }

  button {
    padding: 0;
    border: 0;
    background: 0;
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.6;
    }

    &:focus {
      outline: 0;
    }
  }
`

export const Kota = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3rem;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  p {
    margin: 0;

    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      font-size: 2rem;
    }
  }

  svg {
    margin-left: 1.5rem;
    width: 3.5rem;
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 50%;
  max-width: 76rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  overflow: hidden;
  z-index: 1000;

  @media (max-width: 1060px) {
    padding: 0 30px;
    max-height: 80vh;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  h3 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 3.5rem;
    font-weight: 300;
  }

  p {
    max-width: 70ch;
  }
`

export const PageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background: rgba(0, 0, 0, 0.9);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }
`

export const Close = styled.button`
  position: fixed;
  top: 2.5rem;
  right: 3.5rem;
  width: 2.8rem;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  z-index: 1100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &:focus {
    outline: 0;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  svg {
    width: 100%;
  }
`

export const ModalContent = styled.div`
  position: relative;
  height: 100%;
  max-height: 50vh;
  overflow-y: scroll;

  @media (max-width: 1060px) {
    padding: 0 30px 0 0;
    max-height: 80vh;
  }

  a {
    color: #fff;
  }

  ul {
    margin-left: 20px;
  }

  li {
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 1.5rem;
    line-height: 2.1;
    color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
  }
`
