import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactPlayer from 'react-player'

import { VideoContainer, VideoOuter, VideoInner, Overlay, Text, Title } from './styles'

const BookApp = () => {
  const [clicked, setClicked] = useState(false)

  const posterImage = useStaticQuery(graphql`
    {
      file(dir: { regex: "/src/" }, name: { eq: "video-poster" }) {
        publicURL
      }
    }
  `)

  return (
    <VideoContainer>
      <VideoOuter>
        <Title>About the App</Title>
        <VideoInner>
          <Overlay className={clicked ? 'clicked' : ''}>
            <Text>
              <p>Introducing the Understanding Jewellery App</p>
            </Text>
          </Overlay>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=Jjtbd6pjSdM&t"
            light={posterImage.file.publicURL}
            width="100%"
            height="100%"
            playing={clicked}
            controls
            onClick={() => setClicked(true)}
          />
        </VideoInner>
      </VideoOuter>
    </VideoContainer>
  )
}

export default BookApp
