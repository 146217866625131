import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import About from '../components/about'
import BookApp from '../components/book-app'
import Video from '../components/video'
import More from '../components/more'
import CookieBanner from '../components/cookie-banner'

const Home = () => (
  <Layout>
    <Hero />
    <About />
    <BookApp />
    <Video />
    <More />
    <CookieBanner />
  </Layout>
)

export default Home
