import styled from 'styled-components'

export const FormOuter = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  input {
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 3rem;
    border: 0;
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 3rem;
    font-style: italic;
    color: rgba(255, 255, 255, 1);
    margin-top: 3rem;
    transition: border 0.3s ease;
    padding: 0 0 1rem;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }

    &::placeholder {
      font-family: ${props => props.theme.fonts.baskerville};
      font-size: 3rem;
      font-style: italic;
      color: rgba(255, 255, 255, 0.5);
    }

    &[type='text'] {
      width: calc(50% - 1.5rem);
      border-radius: 0;

      &:first-child {
        margin-right: 3rem;
      }
    }

    &[type='email'] {
      position: relative;
      width: 100%;
      border-radius: 0;
      z-index: 1;
    }
  }
`

export const SubmitWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SubmitButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: 0;
  cursor: pointer;
  transition: background 0.3s ease;
  pointer-events: all;
  z-index: 5;

  &:focus {
    outline: 0;
  }
`

export const Form = styled.form`
  &.blur {
    filter: blur(5px);
    pointer-events: none;
  }
`

export const SubmitButtonOuter = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.7rem;
  height: 4.7rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 10;

  &:hover {
    svg {
      stroke: #000;
    }

    ${SubmitButton} {
      background: #fff;
    }
  }

  svg {
    position: relative;
    width: 1.7rem;
    stroke: #fff;
    pointer-events: none;
    z-index: 10;
  }
`

export const CheckboxOuter = styled.label`
  display: flex;
  margin: 0 !important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

export const FormBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
`

export const FormSuccess = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 36rem;
  padding: 0 2rem;
  text-align: center;
  font-weight: 500;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 5;

  &.active {
    opacity: 1;
  }

  p {
    text-align: center;
    color: #fff;
    margin: 0;

    a {
      color: #fff;
      pointer-events: all;
    }
  }
`
