import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Button from '../button'

import { BookAppOuter, Book, BookText, BookImage, App, AppText, AppImage } from './styles'

const BookApp = () => {
  const images = useStaticQuery(graphql`
    {
      allFile(filter: { dir: { regex: "/src/" }, extension: { eq: "png" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const bookImage = images.allFile.edges.find(image =>
    image.node.childImageSharp.fluid.src.includes('book.png')
  )

  const appImage = images.allFile.edges.find(image =>
    image.node.childImageSharp.fluid.src.includes('app.png')
  )

  return (
    <BookAppOuter>
      <Book>
        <BookText>
          <h2>The Book</h2>
          <p>
            This publication by David and Daniela has been constantly in print since 1989 and is
            known by jewellery professionals as ‘The Bible’. It is considered to be the ultimate
            resource for experts, collectors and enthusiasts alike. Currently it is available as a
            hardback book in its third revised edition. David and Daniela are now working on a major
            new work <em>Understanding Jewellery: The 20th Century</em> to be published later this
            year.
          </p>
          <Button
            text="Get the book"
            link="https://www.amazon.co.uk/Understanding-Jewellery-David-Bennett/dp/1851494308/ref=sr_1_2?crid=15AV2MZJPBEB9&keywords=understanding+jewellery&qid=1581591583&sprefix=understanding+jewellery%2Caps%2C155&sr=8-2"
          />
        </BookText>
        <div>
          <BookImage fluid={bookImage.node.childImageSharp.fluid} />
        </div>
      </Book>
      <App>
        <div>
          <AppImage fluid={appImage.node.childImageSharp.fluid} />
        </div>
        <AppText>
          <h2>The App</h2>
          <p>
            The Understanding Jewellery App is so much more than a digital form of the book, it is
            the definitive, dynamic and interactive jewellery App. The App works on all tablet
            devices and provides a new and unique way to look at the history, style and design of
            jewellery. Its simple search functionality makes it a fantastic resource to help
            identify gemstones and discover the evolution of jewellery since 1750.
          </p>
          <Button text="Apple App Store" link="https://apps.apple.com/app/id1335553499" />
          <Button
            text="Google Play Store"
            link="https://play.google.com/store/apps/details?id=com.digitalistgroup.understandingjewellery&hl=en_US"
          />
        </AppText>
      </App>
    </BookAppOuter>
  )
}

export default BookApp
