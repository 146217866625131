import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LogoSvg from '../../images/logo.svg'
import AppStoreSvg from '../../images/app-store.svg'
import PlayStoreSvg from '../../images/play-store.svg'
import ScrollSvg from '../../images/scroll.svg'
import DownArrowSvg from '../../images/down-arrow.svg'

import {
  ContentWrap,
  HeroOuter,
  ImageWrap,
  Image,
  Text,
  Logo,
  Sidebar,
  AppStore,
  PlayStore,
  Scroll,
  MobileScroll
} from './styles'

const HomeHero = () => {
  const data = useStaticQuery(graphql`
    {
      file(dir: { regex: "/src/" }, name: { eq: "hero-full" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <HeroOuter>
      <Sidebar>
        <AppStore href="https://apps.apple.com/app/id1335553499" target="_blank">
          <AppStoreSvg />
        </AppStore>
        <PlayStore
          href="https://play.google.com/store/apps/details?id=com.digitalistgroup.understandingjewellery&hl=en_US"
          target="_blank"
        >
          <PlayStoreSvg />
        </PlayStore>
        <Scroll>
          <ScrollSvg />
        </Scroll>
      </Sidebar>
      <ContentWrap>
        <Text>
          <Logo>
            <LogoSvg />
          </Logo>
          <h1>The eternal fascination, the enduring magic</h1>
          <p>
            For David Bennett and Daniela Mascetti jewels are so much more than just precious
            ornaments. They are also about passion and a deep fascination that lasted over 40 years
            in their work as Worldwide Chairman and European Chairman respectively of Sotheby’s
            Jewellery Division.
          </p>

          <p>
            <small>Full website coming soon</small>
          </p>
        </Text>
        <MobileScroll>
          <p>Scroll</p>
          <DownArrowSvg />
        </MobileScroll>
        <ImageWrap>
          <Image fluid={data.file.childImageSharp.fluid} style={{ position: 'absolute' }} />
        </ImageWrap>
      </ContentWrap>
    </HeroOuter>
  )
}

export default HomeHero
