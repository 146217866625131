import React from 'react'
import usePortal from 'react-useportal'

import Form from '../form'

import KotaLogoSvg from '../../images/kota-logo.svg'
import CloseSvg from '../../images/close.svg'
import TwitterSvg from '../../images/twitter.svg'
import InstagramSvg from '../../images/instagram.svg'
import FacebookSvg from '../../images/facebook.svg'
import LinkedinSvg from '../../images/linkedin.svg'

import {
  PageFooter,
  Left,
  Right,
  Heading,
  LeftInner,
  RightInner,
  Phone,
  Email,
  Social,
  Copyright,
  Kota,
  Modal,
  PageOverlay,
  Close,
  ModalContent
} from './styles'

const Footer = () => {
  const [openPortal, closePortal, isOpen, Portal] = usePortal()

  return (
    <>
      <PageFooter>
        <Left>
          <LeftInner>
            <Heading>Newsletter</Heading>
            <p>
              The Understanding Jewellery website will launch soon. Sign up here to subscribe to the
              Understanding Jewellery newsletter and stay up to date with developments.
            </p>
            <Form />
          </LeftInner>
        </Left>
        <Right>
          <RightInner>
            <Phone>
              <a href="tel:+44 7946 434855">+44 7946 434855</a>
            </Phone>
            <Email>
              <a
                href="mailto:info@understanding-jewellery.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@understanding-jewellery.com
              </a>
            </Email>
            <Social>
              <a
                href="https://www.instagram.com/understandingjewellery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramSvg />
              </a>
              <a
                href="https://www.linkedin.com/company/19192787/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinSvg />
              </a>
              <a href="https://twitter.com/UJewellery_" target="_blank" rel="noopener noreferrer">
                <TwitterSvg />
              </a>
              <a
                href="https://www.facebook.com/UnderstandingJewellery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookSvg />
              </a>
            </Social>
            <Copyright>
              <p>Understanding Jewellery © 2020</p>
              <p>All rights reserved</p>
              <p>
                <button type="button" onClick={openPortal}>
                  Privacy Policy
                </button>
              </p>
            </Copyright>
            <Kota href="https://kota.co.uk" target="_blank">
              <p>Site by</p>
              <KotaLogoSvg />
            </Kota>
          </RightInner>
        </Right>
      </PageFooter>
      <Portal>
        <Close onClick={closePortal} className={isOpen ? 'active' : ''}>
          <CloseSvg />
        </Close>
        <Modal className={isOpen ? 'active' : ''}>
          <ModalContent>
            <h3>Privacy Policy</h3>
            <p className="p1">
              Please read the following information carefully. This privacy notice contains
              information about the information collected, stored and otherwise processed about you
              and the reasons for the processing. It also tells you who we share this information
              with, the security mechanisms we have put in place to protect your data and how to
              contact us in the event you need further information.
            </p>
            <p className="p1">
              <strong>About this Privacy Policy</strong>
            </p>
            <p className="p2">
              <span className="s1">This is the policy of "</span>Understanding Jewellery Sarl." In
              this document, "we", "our", or "us" refer to Understanding Jewellery Sarl.
            </p>
            <p className="p2">We are company number CHE-429.072.726 registered in Switzerland.</p>
            <p className="p2">
              Our registered office is at 25 Route Suisse, 1296 Coppet, Switzerland.{' '}
              <span className="s1">It applies when you </span>visit our website, when services are
              provided by us and when we process data.
            </p>
            <p className="p1">
              We collect, use and are responsible for personal information about you. When we do
              this we are acting as ‘controller’ of this information for the purposes of
              international statutory requirements.
            </p>
            <p className="p1">
              Unless you are notified to the contrary, we will adhere to this policy. This privacy
              policy will be continually reviewed and updated. Therefore, we encourage you to review
              it periodically in order to see the most up to date policy. We will not substantially
              change the way we use your data without your prior agreement.
            </p>
            <p className="p1">
              <strong>Contacting Us</strong>
            </p>
            <p className="p1">
              If you wish to contact us about your data or the processing carried out, or to
              exercise any of your rights under the applicable data protection laws, you can use our
              registered office for our contact details.
            </p>
            <strong>1. Data we process</strong>
            <p className="p1">
              We may collect, use, store and transfer different kinds of personal data about you.
            </p>
            <p className="p1">We have collated these into groups as follows:</p>
            <p className="p1">
              Your identity includes information such as first name, last name, title, date of
              birth, and other identifiers that you may have provided at some time.
            </p>
            <p className="p1">
              Your contact information includes information such as billing address, delivery
              address, email address, telephone numbers and any other information you have given to
              us for the purpose of communication or meeting.
            </p>
            <p className="p1">
              Technical data includes your internet protocol (IP) address, browser type and version,
              time zone setting and location, browser plug-in types and versions, operating system
              and platform and other technology on the devices you use to access this website.
            </p>
            <p className="p1">
              We may aggregate anonymous data such as statistical or demographic data for any
              purpose. Anonymous data is data that does not identify you as an individual.
            </p>
            <p className="p1">
              Aggregated data may be derived from your personal data but is not considered personal
              information in law because it does not reveal your identity.
            </p>
            <p className="p1">
              For example, we may aggregate profile data to assess interest in a product or service.
            </p>
            <p className="p1">
              However, if we combine or connect aggregated data with your personal information so
              that it can identify you in any way, we treat the combined data as personal
              information and it will be used in accordance with this privacy notice.
            </p>
            <p className="p1">
              <strong>2. Special personal information</strong>
            </p>
            <p className="p1">
              Special personal information is data about your race or ethnicity, religious or
              philosophical beliefs, sex life, sexual orientation, political opinions, trade union
              membership, information about your health and genetic and biometric data.
            </p>
            <p className="p1">
              It also includes information about criminal convictions and offences.
            </p>
            <p className="p1">We do not collect any special personal information about you.</p>
            <p className="p1">
              <strong>3. If you do not provide personal information we need</strong>
            </p>
            <p className="p1">
              Where we need to collect personal data by law, or under the terms of a contract we
              have with you, and you fail to provide that data when requested, we may not be able to
              perform that contract. In that case, we may have to stop providing a service to you.
              If so, we will notify you of this at the time.
            </p>
            <p className="p1">
              <strong>The bases on which we process information about you</strong>
            </p>
            <p className="p1">
              The law requires us to determine under which of six defined bases we process different
              categories of your personal information, and to notify you of the basis for each
              category.
            </p>
            <p className="p1">
              If a basis on which we process your personal information is no longer relevant then we
              shall immediately stop processing your data.
            </p>
            <p className="p1">
              If the basis changes then if required by law we shall notify you of the change and of
              any new basis under which we have determined that we can continue to process your
              information.
            </p>
            <p className="p1">
              <strong>4. Information we process with your consent</strong>
            </p>
            <p className="p1">
              Through certain actions when otherwise there is no contractual relationship between
              us, such as when you browse our website or ask us to provide you more information
              about our business, including our products and services, you provide your consent to
              us to process information that may be personal information.
            </p>
            <p className="p1">
              Wherever possible, we aim to obtain your explicit consent to process this information,
              for example, by asking you to agree to our use of cookies.
            </p>
            <p className="p1">
              If you have given us explicit permission to do so, we may from time to time pass your
              name and contact information to selected associates whom we consider may provide
              services or products you would find useful.
            </p>
            <p className="p1">
              We continue to process your information on this basis until you withdraw your consent
              or it can be reasonably assumed that your consent no longer exists.
            </p>
            <p className="p1">
              You may withdraw your consent at any time by instructing us at
              info@understandingjewellery.com. However, if you do so, you may not be able to use our
              website or our services further.
            </p>
            <p className="p1">
              <strong>5. Information we process for the purposes of legitimate interests</strong>
            </p>
            <p className="p1">
              We may process information on the basis there is a legitimate interest, either to you
              or to us, of doing so.
            </p>
            <p className="p1">
              Where we process your information on this basis, we do after having given careful
              consideration to:
            </p>

            <ul>
              <li>whether the same objective could be achieved through other means</li>
              <li>whether processing (or not processing) might cause you harm</li>
              <li className="p1">
                whether you would expect us to process your data, and whether you would, in the
                round, consider it reasonable to do so
              </li>
            </ul>
            <p className="p1">
              For example, we may process your data on this basis for the purposes of:
            </p>

            <ul>
              <li className="p1">
                record-keeping for the proper and necessary administration of Understanding
                Jewellery Sarl
              </li>
              <li className="p1">
                responding to unsolicited communication from you to which we believe you would
                expect a response
              </li>
              <li className="p1">protecting and asserting the legal rights of any party</li>
              <li className="p1">
                insuring against or obtaining professional advice that is required to manage
                Understanding Jewellery Sarl’s risk
              </li>
              <li className="p1">
                protecting your interests where we believe we have a duty to do so
              </li>
            </ul>
            <p className="p1">
              <strong>6. Information we process because we have a legal obligation</strong>
            </p>
            <p className="p1">
              Sometimes, we must process your information in order to comply with a statutory
              obligation.
            </p>
            <p className="p1">
              For example, we may be required to give information to legal authorities if they so
              request or if they have the proper authorisation such as a search warrant or court
              order.
            </p>
            <p className="p1">This may include your personal information.</p>
            <p className="p1">
              <strong>Information collected from other sources:</strong> The same categories of
              information may also be obtained from third parties, such as other legal professionals
              or experts, members of the public, your family and friends, witnesses, courts and
              other tribunals, investigators, government departments, regulators, public records and
              registers.
            </p>
            <p className="p1">
              <strong>7. Job applications and employment</strong>
            </p>
            <p className="p1">
              If you send us information in connection with a job application, we may keep it for up
              to three years in case we decide to contact you at a later date.
            </p>
            <p className="p1">
              If we employ you, we collect information about you and your work from time to time
              throughout the period of your employment. This information will be used only for
              purposes directly relevant to your employment. After your employment has ended, we
              will keep your file for six years before destroying or deleting it.
            </p>
            <p className="p1">
              <strong>8. Communicating with us</strong>
            </p>
            <p className="p1">
              When you contact us, whether by telephone, through our website or by email, we collect
              the data you have given to us in order to reply with the information you need.
            </p>
            <p className="p1">
              We record your request and our reply in order to increase the efficiency of
              Understanding Jewellery Sarl.
            </p>
            <p className="p1">
              We keep personally identifiable information associated with your message, such as your
              name and email address so as to be able to track our communications with you to
              provide a high quality service.
            </p>
            <p className="p1">
              <strong>9. Complaining</strong>
            </p>
            <p className="p1">
              When we receive a complaint, we record all the information you have given to us.
            </p>
            <p className="p1">We use that information to resolve your complaint.</p>
            <p className="p1">
              If your complaint reasonably requires us to contact some other person, we may decide
              to give to that other person some of the information contained in your complaint. We
              do this as infrequently as possible, but it is a matter for our sole discretion as to
              whether we do give information, and if we do, what that information is.
            </p>
            <p className="p1">
              We may also compile statistics showing information obtained from this source to assess
              the level of service we provide, but not in a way that could identify you or any other
              person.
            </p>
            <p className="p1">
              <strong>10. Affiliate and business partner information</strong>
            </p>
            <p className="p1">
              This is information given to us by you in your capacity as an affiliate of us or as a
              business partner.
            </p>
            <p className="p1">
              It allows us to recognise visitors that you have referred to us, and to credit to you
              commission due for such referrals. It also includes information that allows us to
              transfer commission to you.
            </p>
            <p className="p1">The information is not used for any other purpose.</p>
            <p className="p1">
              We undertake to preserve the confidentiality of the information and of the terms of
              our relationship.
            </p>
            <p className="p1">
              We expect any affiliate or partner to agree to reciprocate this policy.
            </p>
            <p className="p1">
              <strong>
                Use of information we collect through automated systems when you visit our website:
              </strong>
            </p>
            <p className="p1">
              <strong>11. Cookies</strong>
            </p>
            <p className="p1">
              Cookies are small text files that are placed on your computer's hard drive by your web
              browser when you visit any website. They allow information gathered on one web page to
              be stored until it is needed for use on another, allowing a website to provide you
              with a personalised experience and the website owner with statistics about how you use
              the website so that it can be improved.
            </p>
            <p className="p1">
              Some cookies may last for a defined period of time, such as one day or until you close
              your browser. Others last indefinitely.
            </p>
            <p className="p1">
              Your web browser should allow you to delete any you choose. It also should allow you
              to prevent or limit their use.
            </p>
            <p className="p1">
              Our website uses cookies. They are placed by software that operates on our servers,
              and by software operated by third parties whose services we use.
            </p>
            <p className="p1">
              When you first visit our website, we ask you whether you wish us to use cookies. If
              you choose not to accept them, we shall not use them for your visit except to record
              that you have not consented to their use for any other purpose.
            </p>
            <p className="p1">
              If you choose not to opt-in to use cookies or you prevent their use through your
              browser settings, you will not be able to use all the functionality of our website.
            </p>
            <p className="p1">We use cookies in the following ways:</p>

            <ul>
              <li className="p1">to track how you use our website</li>
              <li className="p1">
                to record whether you have seen specific messages we display on our website
              </li>
              <li className="p1">to keep you signed in our site</li>
              <li className="p1">
                to record your answers to surveys and questionnaires on our site while you complete
                them
              </li>
              <li className="p1">
                to record the conversation thread during a live chat with our support team
              </li>
            </ul>
            <p className="p1">
              <strong>12. Personal identifiers from your browsing activity</strong>
            </p>
            <p className="p1">
              Requests by your web browser to our servers for web pages and other content on our
              website are recorded.
            </p>
            <p className="p1">
              We record information such as your geographical location, your Internet service
              provider and your IP address. We also record information about the software you are
              using to browse our website, such as the type of computer or device and the screen
              resolution.
            </p>
            <p className="p1">
              We use this information in aggregate to assess the popularity of the webpages on our
              website and how we perform in providing content to you.
            </p>
            <p className="p1">
              If combined with other information we know about you from previous visits, the data
              possibly could be used to identify you personally, even if you are not signed in to
              our website.
            </p>
            <p className="p1">
              <strong>13. Our use of re-marketing</strong>
            </p>
            <p className="p1">
              Re-marketing involves placing a cookie on your computer when you browse our website in
              order to be able to serve to you an advert for our products or services when you visit
              some other website.
            </p>
            <p className="p1">
              We may use a third party to provide us with re-marketing services from time to time.
            </p>
            <p className="p1">
              If so, then if you have consented to our use of cookies, you may see advertisements
              for our products and services on other websites.
            </p>
            <p className="p1">
              <strong>Disclosure and sharing of your information</strong>
            </p>
            <p className="p1">
              <strong>13. Our use of re-marketing</strong>
            </p>
            <p className="p1">
              Re-marketing involves placing a cookie on your computer when you browse our
            </p>
            <p className="p1">
              website in order to be able to serve to you an advert for our products or services
              when you visit some other website.
            </p>
            <p className="p1">
              We may use a third party to provide us with re-marketing services from time to time.
            </p>
            <p className="p1">
              If so, then if you have consented to our use of cookies, you may see advertisements
              for our products and services on other websites.
            </p>
            <p className="p1">
              <strong>Disclosure and sharing of your information</strong>
            </p>
            <p className="p1">
              <strong>14. Information we obtain from third parties</strong>
            </p>
            <p className="p1">
              Although we do not disclose your personal information to any third party (except as
              set out in this notice), we sometimes receive data that is indirectly made up from
              your personal information from third parties whose services we use.
            </p>
            <p className="p1">No such information is personally identifiable to you.</p>
            <p className="p1">
              <strong>15. Third party advertising on our website</strong>
            </p>
            <p className="p1">
              Third parties may advertise on our website. In doing so, those parties, their agents
              or other companies working for them may use technology that automatically collects
              information about you when their advertisement is displayed on our website.
            </p>
            <p className="p1">
              They may also use other technology such as cookies or JavaScript to personalise the
              content of, and to measure the performance of their adverts.
            </p>
            <p className="p1">
              We do not have control over these technologies or the data that these parties obtain.
            </p>
            <p className="p1">
              Accordingly, this privacy notice does not cover the information practices of these
              third parties.
            </p>
            <p className="p1">
              <strong>16. Credit reference</strong>
            </p>
            <p className="p1">
              To assist in combating fraud, we share information with credit reference agencies, so
              far as it relates to clients or customers who instruct their credit card issuer to
              cancel payment to us without having first provided an acceptable reason to us and
              given us the opportunity to refund their money.
            </p>
            <p className="p1">
              <strong>17. Transfer of your information outside the European Economic Area</strong>
            </p>
            <p className="p1">
              This Privacy Notice is of general application and as such it is not possible to state
              whether it will be necessary to transfer your information out of the EEA in any
              particular case or for a reference. However, if you reside outside the EEA then it may
              be necessary to transfer some of your data to that country outside of the EEA for that
              purpose. If you are in a country outside the EEA or if the instructions you provide
              come from outside the EEA then it is inevitable that information will be transferred
              to those countries. If this applies to you and you wish additional precautions to be
              taken in respect of your information please indicate this when providing initial
              instructions.
            </p>
            <p className="p1">
              Some countries and organisations outside the EEA have been assessed by the European
              Commission and their data protection laws and procedures found to show adequate
              protection. The list can be found{' '}
              <a
                href="https://ec.europa.eu/info/law/lawtopic/dataprotection/data-transfers-outside-eu/adequacy-protection-personal-datanon-eucountries_en#dataprotectionincountriesoutsidetheeu"
                target="_blank"
                rel="noopener"
              >
                here
              </a>
              <span className="s2">. Most do not. If your </span>information has to be transferred
              outside the EEA, then it may not have the same protections and you may not have the
              same rights as you would within the EEA.
            </p>
            <p className="p1">
              <strong>Control over your own information</strong>
            </p>
            <p className="p1">
              <strong>18. Your duty to inform us of changes</strong>
            </p>
            <p className="p1">
              It is important that the personal data we hold about you is accurate and current.
              Please keep us informed if your personal data changes.
            </p>
            <p className="p1">
              <strong>19. Access to your personal information</strong>
            </p>
            <p className="p1">
              To obtain a copy of any information that is not provided on our website you should
              contact us to make that request.
            </p>
            <p className="p1">
              After receiving the request, we will tell you when we expect to provide you with the
              information, and whether we require any fee for providing it to you.
            </p>
            <p className="p1">
              <strong>20. Removal of your information</strong>
            </p>
            <p className="p1">
              If you wish us to remove personally identifiable information from our website, you
              should contact us to make your request.
            </p>
            <p className="p1">This may limit the service we can provide to you.</p>
            <p className="p1">
              <strong>21. Verification of your information</strong>
            </p>
            <p className="p1">
              When we receive any request to access, edit or delete personal identifiable
              information we shall first take reasonable steps to verify your identity before
              granting you access or otherwise taking any action. This is important to safeguard
              your information.
            </p>
            <p className="p1">
              <strong>Other matters</strong>
            </p>
            <p className="p1">
              <strong>22. Use of site by children</strong>
            </p>
            <p className="p1">
              We do not sell products or provide services for purchase by children, nor do we market
              to children.
            </p>
            <p className="p1">
              If you are under 18, you may use our website only with consent from a parent or
              guardian
            </p>
            <p className="p1">
              We collect data about all users of and visitors to the website regardless of age, and
              we anticipate that some of those users and visitors will be children.
            </p>
            <p className="p1">
              Such child users and visitors will inevitably visit other parts of the site and will
              be subject to whatever on-site marketing they find, wherever they visit.
            </p>
            <p className="p1">
              <strong>23. Encryption of data sent between us</strong>
            </p>
            <p className="p1">
              We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser
              and to encrypt any data you give us.
            </p>
            <p className="p1">
              Whenever information is transferred between us, you can check that it is done so using
              SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL
              bar or toolbar.
            </p>
            <p className="p1">
              <strong>24. How you can complain</strong>
            </p>
            <p className="p1">
              If you are not happy with our privacy policy or if you have any complaint then you
              should tell us.
            </p>
            <p className="p1">
              If a dispute is not settled then we hope you will agree to attempt to resolve it by
              engaging in good faith with us in a process of mediation or arbitration.
            </p>
            <p className="p1">
              If you are in any way dissatisfied about how we process your personal information, you
              have a right to lodge a complaint with the Information Commissioner's Office (ICO).
              This can be done at{' '}
              <a href="https://ico.org.uk/make-a-complaint/" target="_blank" rel="noopener">
                <span className="s1">https://ico.org.uk/make-a-complaint/</span>
              </a>
              . We would, however, appreciate the opportunity to talk to you about your concern
              before you approach the ICO.
            </p>
            <p className="p1">
              <strong>25. How long will we store your personal data?</strong>
            </p>
            <p className="p1">We will normally store all your information:</p>
            <p className="p1">
              Until at least 1 year after the expiry of any relevant limitation period (which will
              usually be 6 years, but may be 12 years, or longer where the case includes information
              relating to a minor), from the date of the last item of work carried out or the date
              of the last payment received or the date on which all outstanding payments are written
              off, whichever is the latest.
            </p>
            <p className="p1">
              Names and contact details held for marketing purposes will be stored indefinitely or
              until we become aware or are informed that the individual has ceased to be a potential
              client.
            </p>
            <p className="p1">
              <strong>26. Consent</strong>
            </p>
            <p className="p1">
              As explained above, we rely on your explicit consent to process your information in
              the categories above.
            </p>
            <p className="p1">
              You have the right to withdraw this consent at any time, but this will not affect the
              lawfulness of any processing activity we have carried out prior to you withdrawing
              your consent. However, where we also rely on other bases for processing your
              information, you may not be able to prevent processing of your data.
            </p>
            <p className="p1">
              If there is an issue with the processing of your information, please contact us using
              the contact details above.
            </p>
            <p className="p1">
              <strong>27. Your rights</strong>
            </p>
            <p className="p1">
              Under the GDPR, you have a number of rights that you can exercise in certain
              circumstances. These are free of charge. In summary, you may have the right to:
            </p>

            <ul>
              <li className="p2">
                Ask for access to your personal information and other supplementary information;
              </li>
              <li className="p2">
                Ask for correction of mistakes in your data or to complete missing information we
                hold on you;
              </li>
              <li className="p2">
                Ask for your personal information to be erased, in certain circumstances;
              </li>
              <li className="p2">
                Receive a copy of the personal information you have provided to us or have this
                information sent to a third party. This will be provided to you or the third party
                in a structured, commonly used and machine readable format, e.g. a Word file;
              </li>
              <li className="p2">
                Object at any time to processing of your personal information for direct marketing;
              </li>
              <li className="p2">
                Object in certain other situations to the continued processing of your personal
                information;
              </li>
              <li className="p2">
                Restrict my processing of your personal information in certain circumstance
              </li>
              <li className="p2">
                Request not to be the subject to automated decision-making which produces legal
                effects that concern you or affects you in a significant way.
              </li>
            </ul>
            <p className="p1">
              If you want more information about your rights under the GDPR please see the
            </p>
            <p className="p1">
              Guidance from the Information Commissioners Office on Individual’s rights under the{' '}
              <span className="s1">GDPR (</span>
              <a
                href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protectionregulationgdpr/individual-rights/"
                target="_blank"
                rel="noopener"
              >
                https://ico.org.uk/for-organisations/guide-to-the-general-data-protectionregulationgdpr/individual-rights/
              </a>
              <span className="s1">).</span>
            </p>
            <p className="p2">If you want to exercise any of these rights, please:</p>

            <ul>
              <li className="p3">Use the contact details above;</li>
              <li className="p3">
                We may need to ask you to provide other information so that you can be identified;
              </li>
              <li className="p3">
                Please provide a contact address so that you can be contacted to request further
                information to verify your identity;
              </li>
              <li className="p3">Provide proof of your identity and address;</li>
              <li className="p3">State the right or rights that you wish to exercise.</li>
            </ul>
            <p className="p2">
              We will respond to you within one month from when we receive your request.
            </p>
            <p className="p1">
              <strong>28. Marketing Emails</strong>
            </p>
            <p className="p1">
              Please note if you wish to unsubscribe from any marketing emails that you have signed
              up for, you can do so by responding to the email with the subject heading
              ‘unsubscribe’. It may take up to one month for this to become effective.
            </p>
            <p className="p1">
              <strong>29. How to make a complaint</strong>
            </p>
            <p className="p1">
              The GDPR also gives you the right to lodge a complaint with the supervisory authority
              of the Member State where you work, normally live or where the alleged infringement of
              data protection laws occurred.
            </p>
            <p className="p1">
              <strong>30. Future Processing</strong>
            </p>
            <p className="p1">
              We do not intend to process your personal information except for the reasons stated
              within this privacy notice. If this changes, this privacy notice will be amended and
              so we recommend you to check for updates periodically.
            </p>
            <p className="p1">
              <strong>31. Compliance with the law</strong>
            </p>
            <p className="p1">
              Our privacy policy has been compiled so as to comply with the law of every country or
              legal jurisdiction in which we aim to do business. If you think it fails to satisfy
              the law of your jurisdiction, we should like to hear from you.
            </p>
            <p className="p1">
              However, ultimately it is your choice as to whether you wish to use our website.
            </p>
            <p className="p1">
              <strong>32. Review of this privacy policy</strong>
            </p>
            <p className="p1">
              We may update this privacy notice from time to time as necessary. The terms that apply
              to you are those posted here on our website on the day you use our website. We advise
              you to print a copy for your records.
            </p>
            <p className="p1">
              If you have any question regarding our privacy policy, please email:{' '}
              <a href="mailto:info@understanding-jewellery.com">info@understanding-jewellery.com</a>
              <span className="s1">.</span>
            </p>
            <p className="p1">
              <strong>
                Dated: 1<span className="s2">st </span>March 2020
              </strong>
            </p>
          </ModalContent>
        </Modal>
        <PageOverlay className={isOpen ? 'active' : ''} />
      </Portal>
    </>
  )
}

export default Footer
