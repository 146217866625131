import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

export const HeroOuter = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #121212;
  overflow: visible;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10%;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */
    z-index: 1;
  }
`

export const ContentWrap = styled.div`
  display: flex;
  max-width: 130rem;
  width: 100%;
  margin: 0 0 0 10%;

  @media (max-width: 850px) {
    margin: 0 0 0 15%;
  }

  @media (max-width: 600px) {
    margin: 0;
    padding: 30px;
    height: 100%;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 10;

  @media (max-width: 600px) {
    height: 100%;
  }

  h1 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: 300;
    margin-top: 2rem;

    @media (max-width: 600px) {
      font-size: 20px;
      text-align: center;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 1.4rem;
    line-height: 1.8;
    letter-spacing: 0;
    max-width: 45ch;
    margin-top: 1.5rem;
    width: 100%;

    @media (max-width: 1000px) {
      font-size: 2rem;
      line-height: 2.1;
    }

    @media (max-width: 600px) {
      text-align: center;
    }

    small {
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 0.35ch;
      text-transform: uppercase;
      color: #c98e7a;
      margin-top: 2rem;

      @media (max-width: 600px) {
        font-size: 1.7rem;
        margin-top: 4rem;
      }
    }
  }
`

export const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* mix-blend-mode: screen; */

  @media (max-width: 600px) {
    opacity: 0.5;

    img {
      object-position: right center !important;
    }
  }
`

export const Image = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: 1;
`

export const Logo = styled.figure`
  width: 49.5rem;
  margin-bottom: 2.5rem;
  z-index: 10;

  @media (max-width: 600px) {
    width: 35rem;
    margin: 0 auto 2rem;
  }

  svg {
    width: 100%;
  }
`

export const Sidebar = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  width: 7.5rem;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 20;

  @media (max-width: 600px) {
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    padding: 2rem;
    border: 0;
  }
`

export const Mark = styled.figure`
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 2.8rem;
  z-index: 10;

  @media (max-width: 600px) {
    position: relative;
    top: initial;
    left: initial;
    width: 5rem;
    transform: translateX(0);
  }

  svg {
    width: 100%;
  }
`

export const AppStore = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.8rem;
  transform: translate(-50%, -100%);
  z-index: 10;

  @media (max-width: 600px) {
    position: relative;
    top: initial;
    left: initial;
    width: 5rem;
    transform: translate(0);
  }

  svg {
    width: 100%;
  }
`

export const PlayStore = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.8rem;
  transform: translate(-50%, 100%);
  opacity: 0.6;
  z-index: 10;

  @media (max-width: 600px) {
    position: relative;
    top: initial;
    left: initial;
    width: 5rem;
    transform: translate(0);
  }

  svg {
    width: 100%;
  }
`

export const Scroll = styled.a`
  position: absolute;
  left: 50%;
  bottom: 3rem;
  width: 2.2rem;
  transform: translateX(-50%);
  z-index: 10;

  @media (max-width: 600px) {
    display: none;
  }

  svg {
    width: 100%;
  }
`

export const MobileScroll = styled.figure`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2.5rem;
  display: none;
  z-index: 10;

  @media (max-width: 600px) {
    display: block;
  }

  strong {
    font-size: 2.5rem;
  }

  p {
    margin: 0;
  }

  svg {
    width: 2.5rem;
  }
`
